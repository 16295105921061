<template>
  <div class="search">
    <div>
      <v-container class="results">
        <div class="d-flex align-center flex-row flex-wrap">
          <v-btn
            v-if="$platform_is_cordova && toPrevious"
            elevation="0"
            outlined
            dark
            x-small
            :to="{ path: $routerHistory.previous().path }"
            class="back-button px-0"
            aria-label="Torna Indietro"
            ><v-icon medium elevation="0">mdi-chevron-left</v-icon></v-btn
          >
          <h1 class="search-title" v-if="query">
            Hai cercato:<span class="highlight"> {{ query }}</span>
          </h1>
          <!-- <h1 class="search-title" v-if="count > 0">: {{ count }} risultati</h1> -->
        </div>
        <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
        <p class="subtitle-2" v-if="realSearch">
          Avevi cercato
          <a class="real_search">{{ realSearch }}</a>
          ma non ha prodotto risultati.
        </p>
      </v-container>
      <ProductListGrid
        :query="query"
        :barcode="barcode"
        :productCodes="product_codes"
        :versionDateFrom="version_date_from"
        :versionDateTo="version_date_to"
        :dataFrom="date_from"
        :dateTo="date_to"
        :webEnabled="web_enabled"
        :parentCategoryId="parent_category_id"
        :key="key"
        :sort="sort"
        mode="search"
        @productsCount="updateCount"
        @searchedText="updateRealSearch"
        position="search"
      />
    </div>
  </div>
</template>

<script>
import ProductListGrid from "@/components/product/ProductListGrid";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SearchProducts",
  // props: ["query", "parent_category_id"],
  components: {
    ProductListGrid
  },
  data() {
    return {
      key: 0,
      count: -1,
      query: null,
      barcode: null,
      product_codes: null,
      version_date_from: null,
      version_date_to: null,
      dateFrom: null,
      dateTo: null,
      parent_category_id: null,
      realSearch: null,
      web_enabled: null,
      sort: null
    };
  },
  mixins: [categoryMixins, deliveryReactive],
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter"
    }),
    toPrevious() {
      var _this = this;
      return _this.$routerHistory.hasPrevious();
    }
  },
  methods: {
    historyBack() {
      window.history.back();
    },
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
    updateCount(count) {
      this.count = count;
    },
    updateRealSearch(searchedText) {
      this.realSearch = this.query;
      this.query = searchedText;
    },
    getQueryParams() {
      this.query = this.$route.query.q;
      this.barcode = this.$route.query.barcode;
      this.product_codes = this.$route.query.product_codes;
      this.version_date_to = this.$route.query.version_date_to;
      this.version_date_from = this.$route.query.version_date_from;
      this.date_from = this.$route.query.date_from;
      this.date_to = this.$route.query.date_to;
      this.web_enabled =
        this.$route.query.web_enabled == null
          ? true
          : String(this.$route.query.web_enabled) === "true";
      // this.queryLabel = this.$route.query.barcode
      //   ? this.$route.query.barcode
      //   : this.$route.query.q;
      this.parent_category_id = this.$route.query.parent_category_id;
      this.filter = this.$route.query.filter;
      this.sort = this.$route.query.sort;
    },
    reload() {
      this.$store.dispatch("category/resetFilters");
      this.key = this.key + 1;
    }
  },
  async created() {
    this.getQueryParams();
    await this.resetFilters("q=" + this.query);
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
      this.key = this.key + 1;
      this.realSearch = null;
      this.count = null;
      await this.resetFilters("q=" + this.query);
    }
  },
  beforeRouteLeave(to, from, next) {
    global.EventBus.$emit("clearSearch");
    next();
  },
  metaInfo() {
    return {
      title: this.$t("navbar.searchAlt")
    };
  }
};
</script>
<style scoped lang="scss">
.search-title {
  color: $primary;
}
</style>
